import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { LiveEventKind, ProgramCategory, ModelNameKind } from '@gql/generated';

export const WELLNESS_ALLOWANCES = Array.from({ length: 50 }).map(
  (_, i) => (i + 1) * 100
);

export const WELLNESS_DEFAULT_ALLOWANCE = 2500;

export const CONTACT_EMAIL = 'info@yogobe.com';
export const VIDEOS_COUNT = 2100;
export const LOGIN_ACTION = 'login';
export const VIDEO_NOT_FOUND_KEY = 'not_found';
export const PAYMENT_CONFIRMATION_ACTION = 'payment_confirmation';
export const VOUCHER_CONFIRMATION_ACTION = 'voucher_confirmation';
export const PROGRAM_CONFIRMATION_ACTION = 'program_confirmation';
export const PAYMENT_FAILED_ACTION = 'payment_failed';
export const PROGRAM_PAYMENT_FAILED_ACTION = 'program_payment_failed';
export const PAUSA_SMART_CHALLENGE_GET_STARTED = 'pausa-smart-challenge';
export const VIDEOS_GENRES_ID = '4a4423f5-3774-47e5-ac70-30ff9df0b3b3';
export const DASHBOARD_PLAYLISTS_CATEGORY_ID =
  '02e12572-1156-46c7-9c0c-b05f7a7c745d';
export const DASHBOARD_PLAYLISTS_IDS = {
  '1cf14e61-d072-473d-9e65-ce6d84b419e6': null,
  '95cc1186-7b3c-4e35-9c75-bef25dc00a29': null,
  '7e538c20-ee09-40ef-805e-ae896b0f62e2': null,
  '401862f3-539f-43da-b15f-a842d42fac5e': null,
  '338d73c7-7417-4955-a96d-911fc0900afa': null,
};

//
export const PROGRAMS_PAGE_SLUG = 'programs';
export const HEALTHCARE_PAGE_SLUG = 'healthcare/programs';
export const CHALLENGES_PAGE_SLUG = 'challenges';

//
export const IOS_MOBILE_SUBSCRIPTION = 'https://apple.co/2Th4vqI';
export const ANDROID_MOBILE_SUBSCRIPTION =
  'https://play.google.com/store/account/subscriptions?package=com.yogobe.mobile';

//
export const IOS_APP_LINK =
  'https://apps.apple.com/se/app/yogobe-yoga-och-meditation/id1445170539';
export const ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.yogobe.mobile';

//
export const ONLINE_COURSE_FEVER_LINK =
  '/se/marketplace/online-courses?utm_source=banners%20onsite&utm_medium=banner&utm_campaign=Course%20Fever_April_2020&utm_content=Course%20Fever_April_2020_banners_onsite';
export const CORONA_SPECIAL_TYPE = 'special_corona';
export const SOMMER_SPECIAL_TYPE = 'special_sommar';
export const PREGLIFE_SPECIAL_TYPE = 'preglife_discount';
export const FALL2020_SPECIAL_TYPE = 'special_fall';
export const SPECIAL_PAYMENT_TYPES = [
  SOMMER_SPECIAL_TYPE,
  CORONA_SPECIAL_TYPE,
  PREGLIFE_SPECIAL_TYPE,
  FALL2020_SPECIAL_TYPE,
  'special',
];

export const HEADER_HEIGHT = 50;
export const USE_NATIVE_TOKEN = '2_BVeVtoennh6rfG6HmvdDc_zAN5rahN1q';

export const AUTH_TOKEN = 'token';
export const AUTH_REFRESH_TOKEN = 'refresh_token';
export const CAMPAIGN_CODE_COOKIE = 'campaign_code';
export const EVENT_VISIT_DATE_COOKIE = 'visit_date';
export const TRIAL_STEP_REQUIRED = 'trial_step_required';
export const CURRENCY_COOKIE = 'y_currency';
export const REGISTER_VISIT = 'register_visit';

export const VERIFICATION_EMAIL_SENT = 'verification_sent';

export const SUMMER_CHALLENGE_LINK = (lang = 'se') =>
  `/${lang}/summerchallenge`;

export const KAROLINSKA_SLUG = 'stress-study2020';

export const ENABLED_LOCALS = ['se', 'en', 'fi', 'no'];
export const SUMMER_CHALLENGE_PROGRAMS = {
  se: [
    {
      id: '3d408d1f-c06f-4369-a1ce-2ca3d1ad5130',
      slug: 'happybeginner',
      title: 'Happy Beginner',
      link: '/se/programs/happybeginner/overview',
      tooltip:
        'Detta program passar dig som är ny till yoga och vill hitta träningsglädjen',
      subtitle: '',
      thumbnail: '/static/happybeginner.jpg',
      description: [
        `Det här är programmet för dig som behöver motivation och pepp för att komma igång med enkel träning och hitta den där glädjen alla snackar om. I detta program får du testa på enklare träning, funktionell träning, yoga för nybörjare, mindfulness samt korta motiverande talks.`,
      ],
    },
    {
      id: 'ae775e1a-441f-4f74-b519-e60709598b76',
      slug: 'completerestoration',
      title: 'Complete Restoration',
      link: '/se/programs/completerestoration/overview',
      tooltip:
        'Detta program passar dig som är i stort behov av återhämtning och vila',
      subtitle: '',
      thumbnail: '/static/completerestoration.jpg',
      description: [
        `Det här är programmet för dig som vill landa efter en period av långvarig stress, smärta eller utmattning - genom att uppleva kroppen med mjuk yoga, få ett lugnare sinne med kortare meditation, yoga nidra och enkla andningstekniker samt ta del av motiverande och betryggande föreläsningar kring stresshantering.`,
      ],
    },
    {
      id: '88acaf92-b5e5-46df-8f2e-df9b21e43e5a',
      slug: 'sweatyandhealthy',
      title: 'Sweaty & Healthy',
      link: '/se/programs/sweatyandhealthy/overview',
      tooltip: 'Detta program passar dig som vill svettas, flåsa och ha kul',
      subtitle: '',
      thumbnail: '/static/sweatyandhealthy.jpg',
      description: [
        `Det här är programmet för dig som är pepp på en fysisk utmaning som kommer ge märkbara resultat i styrka, rörlighet och kondition.
          I detta program får du testa på funktionell träning, utmanande helkroppsklasser, SOMA MOVE, pilates, dynamisk yoga, Barre Move samt motiverande föreläsningar. `,
      ],
    },
    {
      id: '0da49d95-712a-4119-a8bc-513f820a0d92',
      slug: 'godeepandwithin',
      title: 'Go deep & within',
      link: '/se/programs/godeepandwithin/overview',
      tooltip:
        'Detta program passar dig som är erfaren yogi och vill gå djupare i din praktik',
      subtitle: '',
      thumbnail: '/static/godeep.jpg',
      description: [
        `Det här är programmet för dig som är erfaren yogautövare och vill utveckla din asana, fördjupa din filosofiska förståelse samt komma vidare på din spirituella resa.
          I detta program får du ta del av flera korta föreläsningar kring personlig utveckling och yogans filosofi, testa ett par meditationstekniker, landa m.h.a. yoga nidra, väcka sinnet med mantra och chanting samt uppleva och utmana din kropp med avancerade yogaklasser.`,
      ],
    },
  ],
  fi: [
    {
      id: '1950791a-5593-4a48-b7ef-14e8017ae9f0',
      slug: 'joogapolulle',
      title: 'Joogapolulle',
      link: '/fi/programs/joogapolulle/overview',
      tooltip:
        'Tämä ohjelma soveltuu sinulle, joka olet utelias tutustumaan joogaan ja syventämään harjoitustasi',
      subtitle:
        'Suorita 12 harjoitusta neljässä viikossa ja voit voittaa palkinnon!',
      thumbnail: '/static/joogapolulle.jpg',
      description: [
        `Sinulle, joka olet kiinnostunut kurkistamaan joogan ja meditaation maailmaan ja halukas tuomaan lisää itsemyötätuntoa ja kiitollisuutta elämääsi. Haaste tutustuttaa sinut joogan eri tyylisuuntiin ja mindfulnessharjoituksiin sekä inspiroi ja motivoi sinua ylläpitämään päivittäisen harjoituksen.
          Tutustut tulevien viikkojen aikana mm. lempeästi virtaavaan flowjoogaan, pysähtymistä opettavaan passiiviseen yinjoogaan, rauhalliseen hathajoogaan, fyysiseen astangajoogaan ja erilaisiin meditaatiotekniikoihin sekä hengitysharjoituksiin. Lisäksi käymme läpi oikeanlaista tekniikkaa, joka vaikuttaa harjoituksen turvallisuuteen.`,
      ],
    },
    {
      id: '9d006309-53b2-4058-ac5f-eed3df4c4700',
      slug: 'kehomielenyhteys',
      title: 'Kehomielen Yhteys',
      link: '/fi/programs/kehomielenyhteys/overview',
      tooltip:
        'Tämä ohjelma soveltuu sinulle, joka tarvitset lepoa ja tietoista läsnäoloa.',
      subtitle:
        'Suorita 12 harjoitusta neljässä viikossa ja voit voittaa palkinnon!',
      thumbnail: '/static/kehomielenyhteys.jpg',
      description: [
        `Sinulle joka haluat vahvistaa yhteyttä kehomieleen näinä epävarmuuden aikoina, jolloin mieli voi olla täynnä stressiä ja ahdistusta. Tämä haaste on lempeä kutsu takaisin kehoon erilaisten kehomieli –yhteyttä vahvistavien harjoitusten kautta, jotka lisäävät myös notkeutta ja liikkuvuutta.
          Haaste tuo luoksesi aktiivisen flowjoogan, passiivisen yinjoogan, kehomieltä hellivän restoratiivisen joogan, somaattisen liikkeen, erilaiset meditaatio- ja hengitysharjoitukset sekä funktionaalisen liikkeen. Lisäksi se kutsuu koko perheen mukaan lasten joogaan.`,
      ],
    },
  ],
};

export default {
  dev: {
    url: 'http://localhost:4000/',
  },
  origin: {
    url: 'https://yogobe.com',
  },
  beta: {
    url: 'https://beta.yogobe.com',
  },
};

export const PROGRAM_THEMES = {
  workout: t`programs_2021.themes.workout`,
  yoga: t`programs_2021.themes.yoga`,
  meditation: t`programs_2021.themes.meditation`,
  beginner: t`programs_2021.themes.beginner`,
  athletes: t`programs_2021.themes.athletes`,
  breathing: t`programs_2021.themes.breathing`,
  personal_growth: t`programs_2021.themes.personal_growth`,
  diet_and_nutrition: t`programs_2021.themes.diet_and_nutrition`,
  mobility_and_prehab: t`programs_2021.themes.mobility_and_prehab`,
  womens_health: t`programs_2021.themes.womens_health`,
  skills: t`programs_2021.themes.skills`,
  stress_and_anxiety: t`programs_2021.themes.stress_and_anxiety`,
  smart_breaks: t`programs_2021.themes.smart_breaks`,
  philosophy: t`programs_2021.themes.philosophy`,
  postnatal: t`programs_2021.themes.postnatal`,
  prenatal: t`programs_2021.themes.prenatal`,
  restoration: t`programs_2021.themes.restoration`,
  yoga_teachers: t`programs_2021.themes.yoga_teachers`,
};

type ProgramType = Partial<
  Record<
    ProgramCategory,
    {
      text: string;
      listingPageSlug: string;
    }
  >
>;

export const programTypes: ProgramType = {
  [ProgramCategory['Programs']]: {
    text: t`programs_2021.program_page.breadcrumb`,
    listingPageSlug: PROGRAMS_PAGE_SLUG,
  },
  [ProgramCategory['Courses']]: {
    text: t`courses_2021.program_page.breadcrumb`,
    listingPageSlug: 'courses',
  },
  [ProgramCategory['Challenges']]: {
    text: ProgramCategory['Challenges'],
    listingPageSlug: ProgramCategory['Challenges'],
  },
  [ProgramCategory['Healthcare']]: {
    text: ProgramCategory['Healthcare'],
    listingPageSlug: ProgramCategory['Healthcare'],
  },
  [ProgramCategory['LiveCourse']]: {
    text: t`metadata.live_course`,
    listingPageSlug: ProgramCategory['LiveCourse'],
  },
};

type DefaultThemes = Partial<Record<ProgramCategory, string>>;

export const ProgramCategoryNames: DefaultThemes = {
  [ProgramCategory['Courses']]: t`metadata.course`,
  [ProgramCategory['Programs']]: t`metadata.program`,
  [ProgramCategory['Challenges']]: t`metadata.challenge`,
};

export const durations = {
  days: t`metadata.days.label`,
  weeks: t`metadata.weeks.label`,
};

export const LIVE_KINDS: Record<LiveEventKind, React.ReactNode> = {
  donation_class: <Trans id="live.listings_page.filters.donation_class" />,
  talk: <Trans id="live.listings_page.filters.kind_talk" />,
  lecture: <Trans id="live.listings_page.filters.kind_lecture" />,
  classe: <Trans id="live.listings_page.filters.kind_class" />,
  workshop: <Trans id="live.listings_page.filters.kind_workshop" />,
  skills: <Trans id="live.listings_page.filters.kind_skills" />,
  part_of_online_course: (
    <Trans id="live.listings_page.filters.kind_online_course" />
  ),
  free_event: <Trans id="live.listings_page.filters.free_event" />,
  premium: <Trans id="listings_page.filters.premium" />,
  campaign: <Trans id="listings_page.filters.campaign" />,
};

export const SEARCH_MODEL_SLUGS = {
  blog: ModelNameKind.BlogPosts,
  programs: ModelNameKind.GenericPrograms,
  courses: ModelNameKind.Courses,
  pages: ModelNameKind.LandingPages,
  playlists: ModelNameKind.Playlists,
  videos: ModelNameKind.Videos,
  challenges: ModelNameKind.Challenges,
  instructors: ModelNameKind.Instructors,
  live: ModelNameKind.LiveEvents,
  events: ModelNameKind.Events,
};

export const SEARCH_CATEGORY_NAMES = {
  videos: t`search.category.video_audio.title`,
  playlists: t`search.category.playlists.title`,
  live: t`search.category.live_events.title`,
  programs: t`search.category.programs.title`,
  challenges: t`search.category.challenges.title`,
  courses: t`search.category.courses.title`,
  events: t`search.category.events.title`,
  blog: t`search.category.blog.title`,
  pages: t`search.category.pages.title`,
  instructors: t`search.category.instructors.title`,
};

export const DASHBOARD_LEFTRIGHT_PADDING_SECTION = [15, 25, 20];
export const DASHBOARD_TOP_PADDING_SECTION = 25;
export const DASHBOARD_BOTTOM_PADDING_SECTION = 35;

export const DISABLED_PROGRAMS = [
  'summer-challenge-beginner',
  'summer-challenge-advanced',
  'fall-challenge-feel-the-connection',
  'fall-challenge-feelthesweat',
  'fall-challenge-embracethelight',
];

export const REF_BUNDLE = 'bundle';

export const CYCLES = {
  1: t`metadata.month`,
  3: t`metadata.quarter`,
  12: t`metadata.year`,
};

export const getCyclesTrans = () => {
  return {
    monthly: t`settings_subscriptions.monthly`,
    quarterly: t`settings_subscriptions.quarterly`,
    yearly: t`settings_subscriptions.annually`,
  };
};

export const PRICES = {
  1: { EUR: 22, SEK: 229 },
  3: { EUR: 59, SEK: 599 },
  12: { EUR: 220, SEK: 2299 },
};

export const PLAN_DESCRIPTION_VALUES = {
  3: {
    EUR: {
      equivalent_amount: '19.66€',
      saved_amount: '7€',
    },
    SEK: {
      equivalent_amount: '199.66 SEK',
      saved_amount: '88 SEK',
    },
  },
  12: {
    EUR: {
      equivalent_amount: '18.33€',
      saved_amount: '44€',
    },
    SEK: {
      equivalent_amount: '191.58 SEK',
      saved_amount: '449 SEK',
    },
  },
};

export const getSubscriptionPreiodTrans = (months: number) => {
  if (!months || months === 0) return null;

  if (months === 1) return t`settings_subscriptions.monthly`;
  if (months === 3) return t`settings_subscriptions.quarterly`;
  if (months === 12) return t`settings_subscriptions.annually`;
  return t({
    id: 'settings_receipts.months',
    values: {
      number: months,
    },
  });
};

export const LANGUAGES = [
  {
    lang: 'se',
    trans: <Trans id="footer.languages.swedish" message="Swedish" />,
    flag: '🇸🇪',
  },
  {
    lang: 'en',
    trans: <Trans id="footer.languages.english" message="English" />,
    flag: '🇬🇧',
  },
  {
    lang: 'no',
    trans: <Trans id="footer.languages.norwegian" message="Norwegian" />,
    flag: '🇳🇴',
  },
  {
    lang: 'fi',
    trans: <Trans id="footer.languages.finnish" message="Finnish" />,
    flag: '🇫🇮',
  },
];
